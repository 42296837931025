import React, { useCallback, useMemo, useState } from 'react'
import {
  CButton,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
} from '@coreui/react-pro'
import { Controller, useFormContext } from 'react-hook-form'
import { renderOptions } from '../../../utils'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input/react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { renderAccTypeOptions } from '../../../utils/renderUtils/utils'
import { Link, useParams } from 'react-router-dom'
import CountryDropdown from '../../../components/CountryDropdown'
import {
  formatStringUnderscore,
  formatToPT,
} from '../../../utils/formatUtils/utils'
import Select from '../../assets/components/Select'
import { components } from 'react-select'
import { debounce } from 'lodash'
import { useAppDispatch, useAppSelector } from '../../../features/hooks'
import { getRIAFirms } from '../../../features/riaSlice'
import {
  getPlaidData,
  getPlaidReports,
  getUser,
  updateRIAUserStatus,
} from '../../../features/usersSlice'
import CIcon from '@coreui/icons-react'
import { cilChevronRight, cilChevronBottom } from '@coreui/icons'
import TrustedContact from './TrustedContact'
import FinancialProfile from './FinancialProfile'
import UserNotes from './UserNotes'
import PlaidDataModal from './PlaidDataModal'
import PlaidReportModal from './PlaidReportModal'
import PlaidSummarizedReportModal from './PlaidSummarizedReportModal'

const Option = (props: any) => {

  const {
    data: { id, value },
  } = props
  return (
    <>
      <components.Option {...props}>
        {value} (id: {id})
      </components.Option>
    </>
  )
}

const BasicInformation: React.FC<{
  register: any
  control: any
  user: any
  open: boolean
  watch: any
  setOpen: any
  loading: boolean
  setBtnLoading: any
}> = ({
  register,
  control,
  user,
  watch,
  setOpen,
  loading,
  setBtnLoading,
}: any) => {
    const { id }: { id: string } = useParams()
    const [showAnalyzedAccreditationReports, setShowAnalyzedAccreditationReports] = useState(false)
    const [showSummarizedPlaidReports, setShowSummarizedPlaidReports] = useState(false)
    const [showPlaidDataModal, setShowPlaidDataModal] = useState(false)
    const [plaidDataLoading, setPlaidDataLoading] = useState(false)
    const [collapseToggle, setCollapseToggle] = useState({
      employmentSection: false,
      financialtSection: false,
      userNotesSection: false,
    })
    const { columns } = useAppSelector((state) => state.users)
    const dispatch = useAppDispatch()
    const {
      formState: { errors },
      setValue,
    } = useFormContext()

    const handleChange = (value: any) => {
      if (value) {
        setValue(
          'data',
          { id: value.id, label: value.value, value: value.value },
          { shouldDirty: true },
        )
        setValue('riaFirmName', value.value, { shouldDirty: true })
        setValue('riaFirmId', value.id, { shouldDirty: true })
      } else {
        setValue(
          'data',
          { id: null, label: '', value: '' },
          { shouldDirty: true },
        )
        setValue('riaFirmName', '', { shouldDirty: true })
        setValue('riaFirmId', null, { shouldDirty: true })
      }
    }

    const fetchData = useCallback(
      async (e: string) => {
        const data = {
          queryParams: JSON.stringify({
            riaFirmId: {},
            name: {},
            freeTextSearch: e,
            length: 10,
          }),
        }
        if (e.length > 0) {
          const response = await dispatch(getRIAFirms({ found: data }))
          const arr = response.payload?.rows || []
          if (arr && arr.length) {
            const a = arr.map((a: any) => {
              return {
                id: a.riaFirmId,
                value: a.name,
                label: a.name,
              }
            })
            return a
          }
        }
      },
      [dispatch],
    )

    const loadSuggestedOptions = useMemo(
      () =>
        debounce((inputValue, callback) => {
          fetchData(inputValue).then((options: any) => callback(options))
        }, 500),
      [fetchData],
    )

    const unlinkUser = async () => {
      setBtnLoading(true)
      const obj = { ...user }
      obj.parentUser = undefined
      await dispatch(updateRIAUserStatus({ ...obj, riaUserStatus: 'REJECTED' }))
      await dispatch(getUser({ id: user.userId }))
      setBtnLoading(false)
    }

    const handlePlaidClick = async () => {
      setShowPlaidDataModal(true)
      setPlaidDataLoading(true)
      await dispatch(getPlaidData(id))
      setPlaidDataLoading(false)
    }

    const getPlaidAccreditationReports = async () => setShowAnalyzedAccreditationReports(true)

    const geASummarizedPlaidReport = async () => setShowSummarizedPlaidReports(true)

    const handleAnalyzedAccreditationReports = async (id: number) => {
      setShowAnalyzedAccreditationReports(true)
    }

    const {
      isPreferredCustomer,
      completedTotal,
      rollingTotal,
      kycStatus,
      accreditedStatus,
      accreditedType,
      kycNeedsManualReview,
      upholdEmail,
      userId,
      brokerInfoId,
      selfAccreditation,
      accreditedSelection,
      accreditedSubSelection,
      preferredCustomerAt,
      riaFirmId,
      riaReferralUrl,
      clientUserId,
      googleSignInEnabled,
      appleSignInEnabled,
      usResidencyStatus,
      plaidData,
      isFinanciallySophisticated,
      confirmedStatus,
      source,
      hasAttemptedPlaidAccreditation
    } = user

    // User associated with NO FIRM and users RIA status is REJECTED
    const canShowAdvisor =
      !!riaFirmId ||
      (watch('riaUserId') && watch('riaUserStatus') === 'REJECTED') ||
      !watch('riaUserId')

    const canUpdateDOBAndLegalName =
      kycStatus !== 'VERIFIED_AUTO' && kycStatus !== 'VERIFIED_MANUAL'

    const showUsResidencyStatus =
      watch('addresses.0.country') === 'United States' &&
      watch('citizenshipCountry') !== 'United States'

    // return the accreditation types from the columns (do not hardcode them)
    const accreditationTypes = columns?.find((c) => c?.name === 'accreditedType')?.values || []

    return (
      <>
        {showPlaidDataModal && (
          <PlaidDataModal
            data={plaidData}
            handleClose={() => setShowPlaidDataModal(false)}
            loading={plaidDataLoading}
          />
        )}
        {showAnalyzedAccreditationReports && (
          <PlaidReportModal handleClose={() => setShowAnalyzedAccreditationReports(false)} id={id} />
        )}
        {showSummarizedPlaidReports && (
          <PlaidSummarizedReportModal handleClose={() => setShowSummarizedPlaidReports(false)} id={id} />
        )}
        <div className="row g-3 mb-4">
          <div className="col-md-3">
            <CFormLabel>First Name</CFormLabel>
            <CFormInput
              disabled={loading}
              {...register('firstName', { required: 'This field is required' })}
            />
            {errors.firstName && (
              <span className="text-danger">{errors.firstName.message}</span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel>Last Name</CFormLabel>
            <CFormInput
              disabled={loading}
              {...register('lastName', { required: 'This field is required' })}
            />
            {errors.lastName && (
              <span className="text-danger">{errors.lastName.message}</span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel>Email</CFormLabel>
            <CFormInput
              disabled={loading}
              {...register('email', { required: 'This field is required' })}
            />
            {errors.email && (
              <span className="text-danger">{errors.email.message}</span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel>Sign Up Method</CFormLabel>
            <CFormInput
              disabled
              value={
                googleSignInEnabled
                  ? 'Google'
                  : appleSignInEnabled
                    ? 'Apple'
                    : 'Email'
              }
            />
          </div>
          <div className="col-md-3">
            <CFormLabel>Legal Name</CFormLabel>
            <CFormInput
              {...register('legalName')}
              disabled={canUpdateDOBAndLegalName || loading}
            />
          </div>
          <div className="col-md-1">
            <CFormLabel>Birth Day</CFormLabel>
            <CFormInput
              type="number"
              {...register('birthDay', {
                required: !canUpdateDOBAndLegalName ? 'Required' : false,
                max: 31,
                min: 1,
              })}
              disabled={canUpdateDOBAndLegalName || loading}
            />
            {errors.birthDay && (
              <span className="text-danger">{errors.birthDay.message}</span>
            )}
          </div>
          <div className="col-md-1">
            <CFormLabel>Birth Month</CFormLabel>
            <CFormInput
              type="number"
              {...register('birthMonth', {
                required: !canUpdateDOBAndLegalName ? 'Required' : false,
                max: 12,
                min: 1,
              })}
              disabled={canUpdateDOBAndLegalName || loading}
            />
            {errors.birthMonth && (
              <span className="text-danger">{errors.birthMonth.message}</span>
            )}
          </div>
          <div className="col-md-1">
            <CFormLabel>Birth Year</CFormLabel>
            <CFormInput
              type="number"
              {...register('birthYear', {
                required: !canUpdateDOBAndLegalName ? 'Required' : false,
                max: new Date().getFullYear() - 3,
                min: 1900,
              })}
              disabled={canUpdateDOBAndLegalName || loading}
            />
            {errors.birthYear && (
              <span className="text-danger">{errors.birthYear.message}</span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel>Phone Number</CFormLabel>
            <PhoneInput
              disabled={loading}
              name="phone"
              control={control}
              international
              countryCallingCodeEditable={false}
              defaultCountry="US"
              rules={{
                validate: (value: string) =>
                  value ? isValidPhoneNumber(value) : null,
              }}
            />
            {errors.phone && (
              <span className="text-danger">
                Please enter a valid phone number.
              </span>
            )}
          </div>
          {clientUserId && (
            <div className="col-md-3">
              <CFormLabel>Plaid IDV Customer Reference Number</CFormLabel>
              <CFormInput disabled {...register('clientUserId')} />
            </div>
          )}
        </div>
        <div className="row g-3 mb-4 d-flex align-items-center">
          <div className="col-md-3">
            <CFormLabel>Tax ID</CFormLabel>
            <CFormInput disabled={loading} {...register('taxId')} />
          </div>
          <div className="col-md-3">
            <CFormLabel>Foreign Tax ID</CFormLabel>
            <CFormInput disabled={loading} {...register('foreignTaxId')} />
          </div>
          {upholdEmail && (
            <div className="col-md-3">
              <CFormLabel>Uphold Email</CFormLabel>
              <span className="input-group-text">{upholdEmail}</span>
            </div>
          )}
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-3">
            <CFormLabel>KYC Status</CFormLabel>
            <CFormInput disabled value={formatStringUnderscore(kycStatus)} />
          </div>
          <div className="col-md-3 d-flex flex-column align-items-end justify-content-end">
            {kycNeedsManualReview && (
              <CButton disabled={loading} onClick={() => setOpen(true)}>
                Verify Identity
              </CButton>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel>Accredited Status</CFormLabel>
            <CFormSelect
              disabled={
                loading ||
                (kycStatus !== 'VERIFIED_MANUAL' && kycStatus !== 'VERIFIED_AUTO')
              }
              defaultValue={accreditedStatus}
              {...register('accreditedStatus')}
            >
              {renderOptions(user, 'accreditedStatus')}
            </CFormSelect>
          </div>
          <div className="col-md-3">
            <Controller
              control={control}
              name="accreditedCountry"
              render={({ field }) => (
                <CountryDropdown
                  {...field}
                  disabled={loading}
                  name="accreditedCountry"
                  label="Accredited Country"
                  ref={null}
                />
              )}
            />
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-3">
            <CFormLabel>Self Accreditation Choice</CFormLabel>
            <CFormSelect
              disabled={loading}
              defaultValue={selfAccreditation}
              {...register('selfAccreditation')}
            >
              <option value="UNKNOWN">Unknown</option>
              <option value="NET_WORTH">Net Worth</option>
              <option value="ANNUAL_INCOME">Annual Income</option>
              <option value="JOINT_INCOME">Joint Income</option>
              <option value="ENTITY">Entity</option>
              <option value="FOREIGN_INVESTOR">Foreign Investor</option>
              <option value="SECURITIES_LICENSE">Securities License</option>
              <option value="NOT_ACCREDITED">Not Accredited</option>
              <option value="SELF_ACCREDITED">Self Accredited</option>
            </CFormSelect>
          </div>
          <div className="col-md-3">
            <>
              <CFormLabel>Finra CRD Number</CFormLabel>
              <CFormInput
                disabled={loading}
                {...register('accreditedFinraCrdNumber')}
              />
            </>
          </div>
          {watch('accreditedStatus') !== 'NOT_VERIFIED' &&
            watch('accreditedStatus') !== 'FAILED' &&
            watch('accreditedStatus') !== 'UNDER_REVIEW' && (
              <div className="col-md-3">
                <CFormLabel>Accredited Type</CFormLabel>
                <CFormSelect
                  disabled={loading}
                  defaultValue={accreditedType}
                  {...register('accreditedType')}
                >
                  {renderAccTypeOptions(accreditationTypes)}
                </CFormSelect>
              </div>
            )}
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-3">
            <Controller
              control={control}
              name="citizenshipCountry"
              render={({ field }) => (
                <CountryDropdown
                  {...field}
                  disabled={loading}
                  name="citizenshipCountry"
                  label="Citizenship Country"
                  ref={null}
                />
              )}
            />
          </div>
          {showUsResidencyStatus && (
            <div className="col-md-3">
              <CFormLabel>US Residency Status</CFormLabel>
              <CFormSelect
                disabled={loading}
                defaultValue={usResidencyStatus}
                {...register('usResidencyStatus')}
              >
                <option value="NOT_REQUIRED">Not Required</option>
                <option value="NON_RESIDENT_ALIEN">Non-Resident Alien</option>
                <option value="RESIDENT_ALIEN">Resident Alien</option>
              </CFormSelect>
            </div>
          )}
          <div className="col-md-3 d-flex align-items-end">
            {brokerInfoId ? (
              <Link className="w-100" to={`/broker_infos/${brokerInfoId}`}>
                <CButton color="primary text-white">View Broker</CButton>
              </Link>
            ) : (
              <Link to={`/broker_infos/-1?userId=${userId}`}>
                <CButton color="primary text-white">Create Broker</CButton>
              </Link>
            )}
          </div>
          <div className="col-md-3 d-flex align-items-end">
            <CButton onClick={handlePlaidClick} color="primary text-white">
              View Plaid IDV JSON
            </CButton>
          </div>
        </div>
        <div className="row mb-4">
          {accreditedSelection && (
            <div className="col-md-3">
              <CFormLabel>Accredited Selection</CFormLabel>
              <CFormInput
                disabled
                value={formatStringUnderscore(accreditedSelection)}
              />
            </div>
          )}
          {accreditedSubSelection && (
            <div className="col-md-6">
              <CFormLabel>Accredited Sub-Selection</CFormLabel>
              <CFormTextarea
                disabled
                {...register('accreditedSubSelection')}
                value={accreditedSubSelection}
              />
            </div>
          )}
          {canShowAdvisor && (
            <div className="col-md-3">
              <CFormLabel>Advisor Firm</CFormLabel>
              <Select
                options={Option}
                loadSuggestedOptions={loadSuggestedOptions}
                handleChange={(value: any) => handleChange(value)}
                watchValue={watch('riaFirmName')}
                query="ria_firms"
                id={watch('riaFirmId')}
                isClearable
              />
            </div>
          )}
          {riaReferralUrl && (
            <div className="col-md-3">
              <CFormLabel>Advisor Referral Link</CFormLabel>
              <CFormInput disabled {...register('riaReferralUrl')} />
            </div>
          )}
          {watch('parentUser') && watch('riaUserStatus') !== 'REJECTED' && (
            <>
              <div className="col-md-3">
                <div>
                  <CFormLabel>Wealth Advisor</CFormLabel>
                  <Select
                    readOnly
                    watchValue={watch('parentUser.fullName')}
                    query="users"
                    id={watch('parentUser.userId')}
                  />
                </div>
              </div>
              <div className="col-md-3 d-flex align-items-end">
                <CButton onClick={unlinkUser}>Unlink</CButton>
              </div>
            </>
          )}
        </div>
        <div className="row mb-4">
          <div className="col-md-3">
            <CFormLabel>Confirmed Status</CFormLabel>
            <CFormInput
              disabled
              value={confirmedStatus}
            />
          </div>
          {hasAttemptedPlaidAccreditation && <div className='col-md-3 d-flex align-items-end'>
            <CButton onClick={getPlaidAccreditationReports}>Show Raw Plaid Accreditation Reports</CButton>
          </div>}
          {hasAttemptedPlaidAccreditation && <div className='col-md-3 d-flex align-items-end'>
            <CButton onClick={geASummarizedPlaidReport}>Show Summarized Plaid Accreditation Reports</CButton>
          </div>}
          {source && <div className="col-md-3">
            <CFormLabel>Source</CFormLabel>
            <CFormInput
              disabled
              value={source}
            />
          </div>}
        </div>
        <div className="row mb-4">
          <div className="col-md-3 d-flex align-items-center mt-5">
            <div className="form-check">
              <input
                disabled={loading}
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckChecked"
                defaultChecked={isPreferredCustomer}
                {...register('isPreferredCustomer')}
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Preferred Customer
              </label>
            </div>
          </div>
          <div className="col-md-3">
            <CFormLabel>Preferred Customer At</CFormLabel>
            <CFormInput
              value={
                preferredCustomerAt
                  ? formatToPT(preferredCustomerAt, 'MM/DD/YY')
                  : ''
              }
              disabled
            />
          </div>
          <div className="col-md-3">
            <CFormLabel>Completed Total</CFormLabel>
            <span className="input-group-text">{`$${completedTotal && completedTotal > 0
              ? completedTotal?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : 0
              }`}</span>
          </div>
          <div className="col-md-3">
            <CFormLabel>Current Rolling 24 Months Total</CFormLabel>
            <span className="input-group-text">{`$${rollingTotal && rollingTotal > 0
              ? rollingTotal?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : 0
              }`}</span>
          </div>
        </div>
        <div className="col">
          <div className="form-check">
            <input
              disabled
              className="form-check-input"
              type="checkbox"
              id="isFinanciallySophisticated"
              defaultChecked={isFinanciallySophisticated}
              {...register('isFinanciallySophisticated')}
            />
            <label
              className="form-check-label"
              htmlFor="isFinanciallySophisticated"
            >
              Is Financially Sophisticated
            </label>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-3">
            <CButton
              onClick={() =>
                setCollapseToggle({
                  ...collapseToggle,
                  employmentSection: !collapseToggle.employmentSection,
                })
              }
              color="link"
              className="pl-0 ml-0"
            >
              Financial Profile
              <CIcon
                className="ml-4"
                icon={
                  collapseToggle.employmentSection
                    ? cilChevronBottom
                    : cilChevronRight
                }
                style={{ marginLeft: 8 }}
              />
            </CButton>
          </div>
          <FinancialProfile
            loading={loading}
            isVisible={collapseToggle.employmentSection}
          />
        </div>
        <div className="row mt-2">
          <div className="col-md-3">
            <CButton
              onClick={() =>
                setCollapseToggle({
                  ...collapseToggle,
                  financialtSection: !collapseToggle.financialtSection,
                })
              }
              color="link"
              className="pl-0 ml-0"
            >
              Trusted Contact
              <CIcon
                className="ml-4"
                icon={
                  collapseToggle.financialtSection
                    ? cilChevronBottom
                    : cilChevronRight
                }
                style={{ marginLeft: 8 }}
              />
            </CButton>
          </div>
          <TrustedContact
            loading={loading}
            isVisible={collapseToggle.financialtSection}
          />
        </div>
        <div className="row mt-2 mb-5">
          <div className="col-md-3">
            <CButton
              onClick={() =>
                setCollapseToggle({
                  ...collapseToggle,
                  userNotesSection: !collapseToggle.userNotesSection,
                })
              }
              color="link"
              className="pl-0 ml-0"
            >
              User Notes
              <CIcon
                className="ml-4"
                icon={
                  collapseToggle.userNotesSection
                    ? cilChevronBottom
                    : cilChevronRight
                }
                style={{ marginLeft: 8 }}
              />
            </CButton>
          </div>
          <UserNotes
            control={control}
            loading={loading}
            isVisible={collapseToggle.userNotesSection}
          />
        </div>
      </>
    )
  }

export default BasicInformation
