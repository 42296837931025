import moment from 'moment-timezone'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const formatToPT = (date, formatType) => {
  if (typeof date === 'number') {
    return moment(
      new Date(date).toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
      }),
    ).format(formatType)
  } else {
    return date
  }
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const toCamalCase = (str) => {
  const strArr = str.split(/\s+/g)
  for (let i = 0; i < strArr.length; i++) {
    if (strArr[i].length === 0) {
      continue
    }
    strArr[i] = strArr[i][0].toUpperCase().concat(
      strArr[i]
        .split('')
        .splice(1, strArr[i].length - 1)
        .join('')
        .toLowerCase(),
    )
  }
  return strArr.join(' ')
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const breakNotes = (str) => {
  if (str === null || !str) {
    return []
  }
  const newArr = str.split('@@@')
  const noteArr = []
  for (let i = 0; i < newArr.length; i++) {
    let obj = {
      firstName: '',
      notes: '',
      time: '',
    }
    const note = newArr[i]
    if (note.length === 0) {
      continue
    } else if (note.length > 0 && !note.includes('$$$')) {
      if (note === 'null') {
        continue
      } else {
        obj = {
          firstName: 'Unknown',
          notes: note,
          time: 'No Record',
        }
      }
    } else if (note.length > 0 && note.includes('$$$')) {
      const arr = note.split('$$$')
      const arr2 = arr[1].split('%%%')
      if (arr2[1]) {
        obj = { firstName: arr[0], notes: arr2[0], time: arr2[1] }
      } else {
        obj = { firstName: arr[0], notes: arr2[0], time: null }
      }
    }
    noteArr.push(obj)
  }
  return noteArr
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const breakNotesStr = (str) => {
  if (str === null || !str) {
    return ''
  }
  const newArr = str.split('@@@')
  let noteArr = ''
  for (let i = 0; i < newArr.length; i++) {
    let obj = {
      firstName: '',
      notes: '',
      time: '',
    }
    const note = newArr[i]
    if (note.length === 0) {
      continue
    } else if (note.length > 0 && !note.includes('$$$')) {
      if (note === 'null') {
        continue
      } else {
        obj = {
          firstName: 'Unknown',
          notes: note,
          time: 'No Record',
        }
      }
    } else if (note.length > 0 && note.includes('$$$')) {
      const arr = note.split('$$$')
      const arr2 = arr[1].split('%%%')
      if (arr2[1]) {
        obj = `${arr[0]} ${arr2[0]} ${moment(
          new Date(Number(arr2[1])).toISOString(),
        )?.format('MMM DD')} \n`
      } else {
        obj = `${arr[0]} ${arr2[0]} ${null} \n`
      }
    }
    noteArr += obj
  }
  return noteArr
}

export const breakNotesArr = (str) => {
  let data = []
  try {
    return JSON.parse(str)
  } catch (err) {
    const newArr = str.split('@@@')
    for (let i = 0; i < newArr.length; i++) {
      const d = newArr[i]

      if (d.includes('$$$')) {
        const arr1 = d.split('$$$')
        const arr2 = arr1[1].split('%%%')
        if (arr1 && arr2) {
          data.push({
            firstName: arr1[0],
            notes: arr2[0],
            date: moment(new Date(Number(arr2[1])).toISOString())?.format(
              'MMM DD',
            ),
          })
        }
      }
    }
  }
  return data
}

export const formatDecimal = (data) => {
  if (data >= 0) {
    return `$${data.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`
  } else {
    const negativeNumber = Math.abs(data)
    return `-$${negativeNumber.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`
  }
}

export const formatTableRenderers = (rows, columns, isChart = false) => {
  if (!isChart) {
    columns?.forEach((col) => {
      const name = col.name
      if (col?.renderer === 'DateTime' || col?.type === 'DateTime') {
        rows?.forEach((row) => {
          row[name] = row[name] ? formatToPT(row[name], 'MM/DD/YY hh:mm:ss a') : ''
        })
      }
      if (col?.renderer === 'Date' || col?.type === 'Date') {
        rows?.forEach((row) => {
          row[name] = row[name] ? formatToPT(row[name], 'MM/DD/YY') : ''
        })
      }
      if (col?.type === 'String') {
        rows?.forEach((row) => {
          if (row[name]?.includes('_')) {
            row[name] = row[name]
              ? toCamalCase(row[name].replaceAll(/_/gi, ' '))
              : ''
          } else {
            row[name] = row[name] ? row[name] : ''
          }
        })
      }
      if (col?.renderer === 'Money') {
        rows.forEach((row) => {
          row[name] =
            row[name] || row[name] === 0 ? formatDecimal(row[name]) : null
        })
      }
      if (col?.renderer === 'Notes') {
        rows.forEach((row) => {
          if (row.notes && row.notes !== '') {
            row.notes = breakNotesArr(row.notes)
          }
        })
      }
      if (col?.renderer === 'Series') {
        rows.forEach((row) => {
          row[name] = row[name].replace(/Linqto Liquidshares Llc - /, '')
        })
      }
      if (col?.renderer?.includes('Status')) {
        rows.forEach((row) => {
          row[name] = toCamalCase(row[name].replace(/_/, ' '))
        })
      }
      if (col?.label === 'Notes' && !col.renderer) {
        rows.forEach((row) => {
          const { notes } = row || {}
          if (notes) {
            try {
              row[name] = JSON.parse(notes)
            } catch (e) {
              console.log('UNABLE TO PARSE NOTES --- FORMAT TABLE RENDERER ')
              row[name] = []
            }
          }
        })
      }
      col.key = col.name
    })
  } else {
    columns?.forEach((col) => {
      const name = col.name
      if (col?.renderer === 'Date' || col?.type === 'Date') {
        rows?.forEach((row) => {
          row[name] = row[name] ? formatToPT(row[name], 'MM/DD/YY') : ''
        })
      }
    })
  }
  return { rows, columns }
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const formatStringUnderscore = (string) =>
  string.charAt(0).toUpperCase() +
  string.replaceAll('_', ' ').slice(1).toLowerCase()

/**
 * Primarily used to convert UTC date times to Pacific Time
 * Note: Only used for date values that are able to be updated.
 * @param {date} date
 * @returns Date object converted to Pacific Timezone
 */
export const convertUTCDateToPT = (date) => {
  return new Date(
    moment(date).tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:00'),
  )
}

/**
 * Primarily used to convert updated dates to Pacific Time to send to API
 * Note: Only used for date values that are able to be updated.
 * @param {date} date
 * @returns Stringified Date Object in Pacific Time
 */
export const convertDateToPT = (date) => {
  return moment.tz(
    moment(date).format('YYYY-MM-DD 00:00:00'),
    'America/Los_Angeles',
  )
}

/**
 * Used to format cash accounts/cash transactions on user-detail and cash-account detail pages.
 * @param {array} arr
 * @returns Updated array with proper formatting or empty array
 */
export const formatCashAccountAndCashTransactions = (arr) => {
  if (arr && arr.length) {
    const cleanCashAccounts = arr.map((acc) => {
      const obj = {}
      for (const prop in acc) {
        if (acc[prop] !== null) {
          if (
            prop === 'pendingReviewBalance' ||
            prop === 'availableBalance' ||
            prop === 'amount'
          ) {
            obj[prop] = formatDecimal(acc[prop])
          } else {
            obj[prop] = acc[prop]
          }
        }
      }
      return obj
    })
    return cleanCashAccounts
  } else {
    return []
  }
}

/**
 * Strip $ and commas from dollar value
 * @param {*} value
 * @returns - number dollar amount
 */
export const getNumericDollar = (value) =>
  value.toString().replace(/[$-,]/g, '')



const documentTypeDict = {
  USER_VIEW: ['Accreditation Documents'],
  FINANCIAL_ADVISOR_VIEW: ['Financial Advisor Documents'],
  INTERNAL: ['Internal Documents'],
  TAX_WITHHOLDING_AND_REPORTING: ['Tax Withholding and Reporting Documents'],
  USER_TAX: ['User Tax Documents'],
  USER_UPLOADED: ['User Uploaded Documents'],
  MONTHLY_STATEMENT: ['Monthly Statement'],
}
/**
 * Maps the internal type to something CS can decipher.
 * @param {*} type 
* @returns - human readable type
*/
export const resolveDocumentType = (type) => {
  return documentTypeDict[type]
}

/**
 * 
 * @param {number} date 
 * @param {string} format 
 * @returns string
 */
export const formatDateTime = (date, format) => {
  return moment(date).format(format)
}

/**
 * 
 * @param {array} array - array of strings that need to be ordered
 * @param {array} columns - ordered columns that is returned
 * @returns array
 */
export const customOrder = (array, columns) => {
  const orderMap = array.reduce((map, item, index) => {
    map[item] = index;
    return map;
  }, {});
  columns.sort((a, b) => orderMap[a.name] - orderMap[b.name])
  return columns
}
